<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MediaBox></MediaBox>

	2019-06-08	init

-->

<template>
  <div
    :is="elmTag"
    class="MediaBox"
    :class="elmClasses"
    role="button"
    :href="elmHref"
  >
    <div class="MediaBox__wrapper">
      <MhVideo
        class="MediaBox__clip"
        v-if="videoObject"
        :class="{
          'MediaBox__clip--isPortrait': isPortraitImage,
          'MediaBox__clip--isLandscape': isLandscapeImage
        }"
        :videoObject="videoObject"
        :autoplay="_autoplayClip"
        :controls="showVideoControls"
        :playClipOnHover="playClipOnHover"
        :muted="isVideoMuted"
        :allowLoad="pageIsVisible"
        :poster="poster"
        :preload="preload"
        ref="mhVideoRef"
        
      ></MhVideo>

      <!--
        Wenn ein Bild vorhanden ist, wird es ausgegeben.
        Warum hatte ich die Abfrage v-if="image && !_autoplayClip" im Code?
        Sie hat den Zitter-Bug auf Mobile auf den Photographers-Seiten verursacht.
        Ich habe sie wieder entfernt.
      -->
      <!--
      <MhImage class="MediaBox__image" :class="{
          'MediaBox__image--isPortrait' : isPortraitImage,
          'MediaBox__image--isLandscape' : isLandscapeImage,
        }"
        v-if="image && !_autoplayClip"
        :imageObject="image"
        :mode="app.isMqBelowDt ? 'img' : 'cover'"
        :loadingAttr="'eager'"
        :allowLoad="pageIsVisible"
      ></MhImage>
      v-else-if="image"
      -->
      <MhImage
      class="MediaBox__image"
      :class="{
        'MediaBox__image--isPortrait': isPortraitImage,
        'MediaBox__image--isLandscape': isLandscapeImage,
        'MediaBox__image--hide': store.currentlyActiveVideoId === videoObject.id && !isProjectOverlay,
        'MediaBox__image--autoHeight': isProjectOverlay
      }"
      :imageObject="image"
      :mode="app.isMqBelowDt ? 'img' : 'cover'"
      :loadingAttr="'eager'"
      :allowLoad="pageIsVisible"
      ></MhImage>
    </div>

    <div class="MediaBox__title" :class="{
      'MediaBox__title--show': store.currentlyActiveVideoId === videoObject.id
    }">
      <div v-html="title"></div>
      <!-- <div>{{ videoObject.id }}</div> -->
      <!--
			{{image.width}} • {{image.height}} — {{mode}} / {{projectSlug}}
			{{mhImageMode}}
			-->
    </div>

    <div class="MediaBox__inViewportDebug">{{ inViewport }}</div>
  </div>
</template>

<script>
// @ is an alias to /src
//import DevInfos from '@/components/DevInfos.vue'
import { EventBus } from '@/event-bus.js';
import MhImage from './MhImage/MhImage.vue';
import MhVideo from './MhVideo/MhVideo.vue';
import inViewport from '@/mixins/vue-in-viewport-mixin.js';
import { store } from '../store/store.js'

function removeTags(str) {
  if (str === null || str === '') return false;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, '');
}

export default {
  name: 'MediaBox',
  components: {
    MhImage,
    MhVideo,
  },
  mixins: [inViewport],
  props: {
    isProjectOverlay: {
      type: [Boolean],
      default: false,
    },
    title: {
      type: [String],
      default: '',
    },
    projectSlug: {
      type: [String, Boolean],
      default: false,
    },
    image: {
      type: [Object, Boolean],
      default: false,
    },
    clip: {
      type: [Object, Boolean],
      default: false,
    },
    preload: {
      type: [Boolean],
      default: false,
    },
    poster: {
      type: [String, Boolean],
      default: false,
    },
    autoplayClip: {
      type: [String, Boolean],
      default: false,
    },
    playClipOnHover: {
      type: [String, Boolean],
      default: true,
    },
    embedUrl: {
      type: [String, Boolean],
      default: false,
    },
    showVideoControls: {
      type: [String, Boolean],
      default: false,
    },
    isVideoMuted: {
      type: [String, Boolean],
      default: false,
    },
    pageIsVisible: {
      type: [Boolean],
      default: false,
    },
  },
  data() {
    return {
      store
      // Detect the intersection of an element when its top or bottom
      // reaches the vertical center of the viewport
      // https://stackoverflow.com/a/69020644
      //
      //inViewportRootMargin: '-25% 0% -75% 0%',
    };
  },
  watch: {
    pageIsVisible: {
      handler: function(to, from, doLog = false) {
        if (doLog) {
          console.group(
            this.$options.name + ' • watcher • pageIsVisible',
            from,
            to
          );
          console.groupEnd();
        }
      },
      immediate: true,
      deep: false,
    },
    'inViewport.now': function(visible) {
      if (visible) this.isInViewport();
      else this.isOutViewport();
    },
  },
  computed: {
    app() {
      return this.$root.$children[0];
    },
    elmClasses() {
      let classes = [];

      if (this.clip) classes.push(this.$options.name + '--hasClip');
      if (this.image) classes.push(this.$options.name + '--hasImage');
      if (this.elmHref) classes.push(this.$options.name + '--isLink');
      if (this.playClipOnHover)
        classes.push(this.$options.name + '--playClipOnHover');
      if (this.inViewport.now)
        classes.push(this.$options.name + '--isInViewport');
      //classes.push(this.$options.name + '--mode-' + this.mode)

      return classes;
    },
    elmTag() {
      return this.projectSlug ? 'a' : 'div';
    },
    elmHref() {
      const projectSlug = this.projectSlug;
      const link = projectSlug ? '?project=' + this.projectSlug : false;

      return link;
    },
    videoObject() {
      let obj = false;

      if (this.embedUrl) {
        obj = {};
        obj.url = this.embedUrl;
      } else {
        obj = this.clip;
      }

      return obj;
    },
    isPortraitImage() {
      const imageHeight = this._.get(this.image, 'height', 0);
      const imageWidth = this._.get(this.image, 'width', 0);

      return imageHeight > imageWidth;
    },
    isLandscapeImage() {
      return !this.isPortraitImage;
    },
    _autoplayClip() {
      let isValidByMq = this.app.isMqBelowDt;
      let doAutoPlay = false;

      if (isValidByMq && this.inViewport.now) {
        doAutoPlay = true;
      } else {
        doAutoPlay = this.autoplayClip;
      }

      // return doAutoPlay;
      return false;
    },
  },
  methods: {
    onClickBox() {
      console.log('onClickBox', this.elmHref);

      this.$route();
    },
    /*
     */
    isInViewport(doLog = true) {
      //add video to activeVideos
      const videoId = this.videoObject?.id || null;
      if (videoId) {
        this.store.activeVideos.push(this.videoObject?.id);
        if(!this.store.currentlyActiveVideoId) {
          this.store.currentlyActiveVideoId = this.store.activeVideos[Math.floor(Math.random() * (this.store.activeVideos.length))];
        }
      }

      //log
      if (doLog)
        console.log(
          'isInViewport',
          this.$options.name,
          removeTags(this.title),
          '✅'
        );
    },
    isOutViewport(doLog = true) {
      //remove video from activeVideos
      const videoId = this.videoObject?.id || null;
      if (videoId) {
        const oldActiveVideos = this.store.activeVideos;
        const newActiveVideos = oldActiveVideos.filter((id) => id !== videoId);
        this.store.activeVideos = newActiveVideos;
      }

      if(this.videoObject?.id === this.store.currentlyActiveVideoId) {
        this.store.currentlyActiveVideoId = this.store.activeVideos[Math.floor(Math.random() * (this.store.activeVideos.length))];
        // this.store.currentlyActiveVideoId = null;
      }

      //log
      if (doLog)
        console.log(
          'isOutViewport',
          this.$options.name,
          removeTags(this.title),
          '❌'
        );
    },
  },
  created() {
    // this.inViewportRootMargin = this.app.isMqBelowDt
    //   ? '-25% 0% -75% 0%'
    //   : '0px 0px -1px 0px';
    //this.inViewportRootMargin = this.app.isMqBelowDt ? '-25% 0% -75% 0%' : '0px 0px -150px 0px'
    this.inViewportRootMargin = '-25% 0% -25% 0%'
    // this.inViewportRootMargin = '-25% 0% -75% 0%'
  },
  mounted() {},
  destroyed() {},
};
</script>

<style lang="less">
@import (reference) '../less/vars.less';
@import (reference) '../less/mixins.less';
@import (reference) '../less/atoms.less';

@MediaBox : {
  transitionDuration: 0.35s;
};

.MediaBox {
  // debug
  //outline: 1px solid red;
}
.MediaBox {
  // layout + styling
  position: relative;
  //top: 0; left: 0; right: 0; bottom: 0;
  //display: inline-flex;
  overflow: hidden;
  display: block;
  color: inherit;
  color: white;

  display: flex;
  flex-direction: column;


  &__wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }
  
  &__image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity:1;
    z-index: 1;

    transition: all @MediaBox[transitionDuration] ease;
    pointer-events: none;

    &--autoHeight {
      img {
        height: auto !important;
      }
    }
  }
  &--hasClip&--hasImage:hover &__image {
    opacity: 0;
  }
  &__image--hide {
    opacity: 0;
  }
  .MhVideo {
    pointer-events: none;
  }
  &--playClipOnHover .MhVideo {
    pointer-events: auto;
  }

  &__clip {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    transition: all @MediaBox[transitionDuration] ease;
    //opacity: 0;
    //&:hover { opacity: 1; }

    /*
			.MhVideo {
				position: absolute;
				top: 0; left: 0;
				height: 100%; width: 100%;
			}
			.MhVideo__inner {
				padding-bottom: initial !important;
				position: absolute;
				top: 0; left: 0;
				height: 100%; width: 100%;
			}
			.MhVideo__videoElm {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
			*/
  }

  &__title {
    position: absolute;
    // position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 0.2em;
    padding-top: 0.5em;
    padding-bottom: 0.2em;
    // opacity: 1;
    opacity: 0;
    transition: all @MediaBox[transitionDuration] ease;
    text-shadow: 0px 0px 15px fade(black, 50);

    &--show {
      // opacity: 1;
    }

    a {
      transition: all @transitions[linkTransitionDuration] ease;
      color: inherit;
      &:hover {
        color: @swatches[textRed];
      }
    }
  }
  &:hover &__title {
    opacity: 1;
  }

  &__projectLink {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  &__inViewportDebug {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.25em;
    background-color: fade(yellow, 75);
    color: black;
    white-space: pre;
  }
}
.MediaBox {
  // smartphone
  @media @mediaQueries[md] {
    &--isInViewport&--hasImage&--hasClip &__image {
      // opacity: 0;
    }
    &--hasImage:not(&--hasClip) &__image {
      position: relative;
      min-height: 1em;
    }
    &__title {
      // title sind immer sichtbar
      opacity: 1;
      z-index: 1;
    }
    &__clip--isPortrait {
      // hochformat-bilder nehmen nur 9 Spalten, nicht 12 ein
      width: calc(75% - (1rem / 12));
    }
    &__image--isPortrait {
      // hochformat-bilder nehmen nur 9 Spalten, nicht 12 ein
      width: calc(75% - (1rem / 12));
    }
  }
}
</style>
