<!--

	Was macht diese Componente?
	Wofür ist die Componente da?

	Welche $props gibt es?

	Beispiel Code:
		<GridCell></GridCell>

	2021-05-25	init

-->

<template>
	<div class="GridCell" :class="elmClasses" :style="elmStyles" :aspectRatio="_contentAspectRatio" ref="GridCell" v-resize:throttle="onResize">

		<div class="GridCell__aspectRatio" :style="aspectRatioElmStyles"></div>

		<div class="GridCell__content" :style="contentElmStyles">
			<MediaBox v-if="image || clip"
				:image="image"
				:clip="clip"
				:preload="preloadClip"
				:poster="poster"
				:embedUrl="embedUrl"
				:title="title"

				:projectSlug="projectSlug"
				:playClipOnHover="playClipOnHover"
				:autoplayClip="autoplayClip"
				:showVideoControls="showVideoControls"
				:isVideoMuted="isVideoMuted"
				:pageIsVisible="pageIsVisible"
				:isProjectOverlay="isProjectOverlay"
			></MediaBox>

			<div class="GridCell__nextProjectLink">
				{{nextProjectLink}}
			</div>

			<TextBox v-if="text"
				:text="text"
				:textVerticalAlign="textVerticalAlign"
			></TextBox>
		</div>

		<div class="GridCell__debug">
			gridRowHeight: {{gridRowHeight}}<br/>
			columns: {{columns}} <br/>
			rows: {{_rows}} <br/>
			cellAspectRatio: {{_cellAspectRatio}} <br/>
			contentAspectRatio: {{_contentAspectRatio}} <br/>
			<hr/>
			content width: {{contentElmStyles.width}} <br/>
			content height: {{contentElmStyles.height}} <br/>
			content paddingBottom: {{contentElmStyles.paddingBottom}} <br/>
			<!--
			projectSlug: {{projectSlug}}<br/>
			text: "..."<br/>
			textVerticalAlign: {{textVerticalAlign}} <br/>
			mediaMode: {{mediaMode}} <br/>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import { EventBus } from '@/event-bus.js'
	import resize from 'vue-resize-directive'

	//import MhImage from './MhImage/MhImage.vue'
	//import MhVideo from './MhVideo/MhVideo.vue'
	import MediaBox from './MediaBox.vue'
	import TextBox from './TextBox.vue'

	export default {
		name: 'GridCell',
		components: {
			//MhImage,
			//MhVideo,
			MediaBox,
			TextBox,
		},
		directives: {
			resize,
		},
		mixins: [],
		props: {
			isProjectOverlay: {
				type    : [Boolean],
				default : false,
			},
			nextProjectLink : {
				type    : [String],
				default : '',
			},
			columns: {
				type     : [String, Number],
				default  : 4,
				required : true,
			},
			rows: {
				type    : [String, Number],
				default : 1,
			},
			cellAspectRatio: { //  'auto' or 'width:height'
				type     : [String],
				default  : "auto",
			},
			contentAspectRatio: { //  'auto' or 'by text' or 'width:height'
				type     : [String],
				default  : "",
			},

			projectSlug: {
				type    : [String, Boolean],
				default : "",
			},

			text: {
				type    : [String, Boolean],
				default : "",
			},
			textVerticalAlign: {
				type    : [String],
				default : "top",
			},

			title: {
				type    : [String],
				default : "",
			},

			image: {
				type    : [Object, Boolean],
				default : false,
			},

			clip: {
				type    : [Object, Boolean],
				default : false,
			},
			preloadClip: {
				type    : [Boolean],
				default : false,
			},
			poster: {
				type    : [String, Boolean],
				default : false,
			},
			playClipOnHover: {
				type    : [Boolean],
				default : true,
			},
			autoplayClip: {
				type    : [Boolean],
				default : false,
			},
			embedUrl: {
				type    : [String, Boolean],
				default : false,
			},
			showVideoControls: {
				type    : [String, Boolean],
				default : false,
			},
			isVideoMuted: {
				type    : [String, Boolean],
				default : false,
			},
			pageIsVisible: {
				type    : [Boolean],
				default : false,
			},
		},
		data() {
			return {
				isMounted : false,
				showContent : false,
				cellDimensions : {},
				contentElmStyles : {}, // set via setContentElmStyles()
				orientation : undefined, // set via setContentElmStyles()
			}
		},
		watch: {
			pageIsVisible: {
				handler: function( to, from, doLog = false ) {
					if( doLog ){
						console.group( this.$options.name + ' • watcher • pageIsVisible', from, to)
						console.groupEnd()
					}
				},
				immediate : true,
				deep: false,
			},
		},
		computed: {
			_rows(){
				return this.gridRowHeight === 'variable' ? 1 : this.rows
			},
			_cellAspectRatio(){
				return this.gridRowHeight === 'fixed' ? 'auto' : this.cellAspectRatio
			},
			_contentAspectRatio(){
				let aspectRatio = this.contentAspectRatio ? this.contentAspectRatio : this._cellAspectRatio

				aspectRatio = this.contentAspectRatio == 'auto' ? this._cellAspectRatio : aspectRatio

				return aspectRatio
			},

			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.showContent ){
					classes.push(this.$options.name + '--showContent')
				}else{
					classes.push(this.$options.name + '--hideContent')
				}

				classes.push(this.$options.name + '--orientation-' + this.contentOrientation)

				return classes
			},

			aspectRatioElmStyles(){ // cell minimum aspectRatio
				const styles      = {}
				let aspectRatio = this._cellAspectRatio

				if( this.isMounted ){

					if( this.gridRowHeight == 'fixed' && aspectRatio != 'auto' ){
						//aspectRatio = 'auto'
						const cellElm = this.$refs.GridCell
						const cellElmWidth = parseFloat( window.getComputedStyle( cellElm ).width )
						const cellElmHeight = parseFloat( window.getComputedStyle( cellElm ).height )

						aspectRatio = cellElmWidth + ':' + cellElmHeight
					}

					// ratio = auto >>> full span
					if( aspectRatio == 'auto' ){
						styles.height = '100%'
						styles.width = '100%'
					}
					// ratio via paddingBottom
					else{
						const ratioHeightWidth = aspectRatio.split(':')
						const ratioHeight      = ratioHeightWidth[1]
						const ratioWidth       = ratioHeightWidth[0]
						const paddingBottom    = (ratioHeight / ratioWidth) * 100 + '%'

						styles.paddingBottom = paddingBottom
					}

					//
					const mq = this.$mq

					if( this.app.isMqBelowDt ){
						//console.log('mq:', mq)
					}
				}

				return styles
			},
			elmStyles(){ // column and row span
				const styles = {
					gridColumn : 'span ' + this.columns,
					gridRow    : 'span ' + this._rows,
				}

				return styles
			},
			XXXtextWrapperStyles(){
				const styles = {}

				if( this.textVerticalAlign == 'bottom' ){
					styles.justifyContent = 'flex-end'
				}

				return styles
			},
			gridRowHeight(){
				return this.$parent.rowHeight
			},
			contentOrientation(){ // landscape, portrait, quadrat
				const width  = parseInt( this._.get( this.contentElmStyles, 'width', 0 ) )
				const height = parseInt( this._.get( this.contentElmStyles, 'height', 0 ) )
				let ratio    = ''

				if( width >  height   ){ ratio = 'landscape' }
				if( width <  height   ){ ratio = 'portrait' }
				if( width == height   ){ ratio = 'quadrat' }
				if( width+height == 0 ){ ratio = false }

				//console.log('width:', width)
				//console.log('height:', height)

				return ratio
			}
		},
		methods: {
			setContentElmStyles( doLog = false ){
				const styles = {}
				const gridRowHeight = this.gridRowHeight

				const contentAspectRatio = this._contentAspectRatio
				const ratioHeightWidth = contentAspectRatio.split(':')
				const ratioWidth = parseInt( ratioHeightWidth[0] )
				const ratioHeight = parseInt( ratioHeightWidth[1] )
				const ratioMultiplier = ratioWidth / ratioHeight
				const ratioIsLandscape = ratioWidth > ratioHeight ? true : false
				const ratioIsPortrait = ratioHeight > ratioWidth ? true : false
				const ratioIsQuadrat = ratioHeight == ratioWidth ? true : false
				const paddingBottom = (ratioHeight / ratioWidth) * 100 + '%'
				const paddingRight = (ratioWidth / ratioHeight) * 100 + '%'

				const cellElm = this.$refs.GridCell
				const cellElmWidth = parseFloat( window.getComputedStyle( cellElm ).width )
				const cellElmHeight = parseFloat( window.getComputedStyle( cellElm ).height )
				const cellElmRatioMultiplier = cellElmWidth / cellElmHeight
				const cellElmIsLandscape = cellElmWidth > cellElmHeight ? true : false
				const cellElmIsPortrait = cellElmHeight > cellElmWidth ? true : false
				const cellElmIsQuadrat = cellElmHeight == cellElmWidth ? true : false

				/*
						ratioHeight     cellElmHeight
						----------  =   ------------
						ratioWidth		cellElmWidth

						9       		x
						----------  =   ----
						16			    192

				*/

				const getContentElmHeight = (width)=>{
					return (ratioHeight / width) * cellElmWidth
				}
				const getContentElmWidth = (height)=>{
					return (ratioWidth / height) * cellElmHeight
				}

				if( doLog ){
					console.groupCollapsed( this.$options.name + ' • setContentElmStyles()')
					console.log('gridRowHeight:', gridRowHeight)
					console.log('cellElmWidth:', cellElmWidth)
					console.log('cellElmHeight:', cellElmHeight)
					console.log('_cellAspectRatio:', this._cellAspectRatio)
					console.log('cellElmIsQuadrat:', cellElmIsQuadrat)
					console.log('contentAspectRatio:', contentAspectRatio)
				}

				if( gridRowHeight == 'variable' ){

					// passt der content horizontal?
					if( getContentElmHeight(ratioWidth) <= cellElmHeight ){
						if( doLog ) console.log('content vertical • height', getContentElmHeight(ratioWidth))

						styles.width = '100%'
						styles.paddingBottom = paddingBottom
					}
					// passt der content vertical?
					if( getContentElmWidth(ratioHeight) <= cellElmWidth ){
						if( doLog ) console.log('content horizontal • width', getContentElmWidth(ratioHeight))

						styles.width = getContentElmWidth(ratioHeight) + 'px'
						styles.height = getContentElmHeight(ratioWidth) + 'px'
						//styles.paddingBottom = ''

						// sonderfall content als quadrat: height = width
						if( ratioWidth == ratioHeight ){
							styles.height = styles.width
						}
						// sonderfall cell als quadrat
						if( cellElmIsQuadrat ){
							styles.height = '100%'
						}
					}
					// _cellAspectRatio = 100 >>> fullspan
					if( this._cellAspectRatio == 'auto' ){
						styles.width = '100%'
						styles.height = '100%'
					}
					// _cellAspectRatio = 100 >>> fullspan
					if( cellElmIsQuadrat ){
						if( doLog ){
							console.log('cell isQuadrat')
							console.log('	contentAspectRatio:', contentAspectRatio)
							console.log('	ratioIsLandscape:', ratioIsLandscape)
							console.log('	ratioIsPortrait:', ratioIsPortrait)
							console.log('	ratioIsQuadrat:', ratioIsQuadrat)
						}
					}
				}
				if( gridRowHeight == 'fixed' ){
					// passt der content vertical?
					if( getContentElmHeight(ratioWidth) <= cellElmHeight ){
						if( doLog ) console.log('content vertical • height', getContentElmHeight(ratioWidth))

						styles.width = '100%'
						styles.paddingBottom = paddingBottom
					}
					// passt der content vertical?
					if( getContentElmWidth(ratioHeight) <= cellElmWidth ){
						if( doLog ) console.log('content horizontal • width', getContentElmWidth(ratioHeight))

						styles.width = getContentElmWidth(ratioHeight) + 'px'
						styles.height = cellElmHeight + 'px'
						//styles.opacity = 0.5

						// sonderfall quadrat: height = width
						if( ratioWidth == ratioHeight ){
							styles.height = styles.width
						}
					}
					// auto = fullspan
					if( contentAspectRatio == 'auto' ){
						styles.width = '100%'
						styles.height = '100%'
					}
				}

				if( doLog ){
					console.groupEnd()
				}

				this.contentElmStyles = styles
			},
			onResize( elm, doLog = false  ){
				const elmWidth = window.getComputedStyle( elm ).width

				if( doLog ){
					console.groupCollapsed( this.$options.name + ' • onResize()')
					console.log('elm:', elm)
					console.log('elmWidth:', elmWidth)
					console.groupEnd()
				}

				this.setContentElmStyles()
			},
		},
		created(){},
		mounted( doLog = false ){
			this.isMounted = true
			this.setContentElmStyles()

			if( doLog ){
				console.groupCollapsed( this.$options.name + ' • mounted()')
				console.log('debugPost:', this.debugPost)
				console.log('$props:', this.$props)
				console.log('clip:', this.clip)
				console.log('image:', this.image)
				console.groupEnd()
			}

			// fade in content with delay to let the dom calculation be invisisble for the user
			// use random delay for every cell to make it nicer :)
			this.showContent = true

			/*
			setTimeout( ()=>{
				this.$nextTick(()=>{
					this.showContent = true
				})
			}, this._.random(150, 750))
			*/
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.HomeView .GridCell:first-child {
		grid-column: span 12 !important;
		grid-area: span 3 / span 12 !important;

		.MediaBox__title {
			opacity: 1;
			height: 100%;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			font-size: 1.7em;
			line-height: 1.2em;
		}
	}

	.GridCell {
		position: relative;
		display: flex;
		flex-direction: column;
		overflow: hidden;

		// &:first-child {
		// 	grid-area: span 1 / span 12 !important;
		// }

		// fade in content
		&--hideContent &__content {
			//transition: all 0.2s ease;
			opacity: 0;
		}
		&--showContent &__content {
			//transition: all 0.2s ease;
			opacity: 1;
		}

		&__debug {
			position: absolute;
			top: 0px; left: 0px;
			padding: 0.5em;
			display: none;

			background-color: lighten( yellow, 20 );
			color: black;
			font-size: 11px;
			line-height: 1.2em;
		}

		&__aspectRatio {
			position: relative;
			//pointer-events: none; // nicer dev
		}
		&__content {
			position: absolute;
			top: 0; left: 0; //right: 0; bottom: 0;
		}

		// sondefall für seiten die nur aus text-boxen bestehen,
		// hier bestimmt die höhe des textes die höhe der cell
		&[aspectRatio="by text"] &__content {
			position: relative;

			.TextBox {
				position: relative;
			}
		}
		&[aspectRatio="by text"] &__aspectRatio {
			display: none;
		}

		/*
		&__textWrapper {
			position: absolute;
			top: 0; left: 0;
			right: 0; bottom: 0;

			display: flex;
			flex-grow: 1;
			flex-direction: column;
		}
		*/


		.MediaBox {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;

			//& > * { visibility: hidden; }
		}
		.MediaBox__clip {
			position: absolute;

			.MhVideo {
				position: absolute;
				top: 0; left: 0;
				height: 100%; width: 100%;
			}
			.MhVideo__inner {
				padding-bottom: initial !important;
				position: absolute;
				top: 0; left: 0;
				height: 100%; width: 100%;
			}
			.MhVideo__videoElm {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}
		//.MediaBox--hasImage:not(.MediaBox--hasClip) {
			.MediaBox__image {
				position: absolute;
				top: 0; bottom: 0; left: 0; right: 0;
			}
		//}
		.TextBox {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
		}

		/*
		@media @mediaQueries[md] {
			grid-column: span 12 !important;

			&.box--spacer {
				background-color: red;
				display: none;
			}

			&__aspectRatio {
				display: none;
			}
			&__content {
				width: 100% !important;
				height: auto !important;
			}

			.MediaBox {
				position: relative !important;
			}
		}
		*/
	}

	// 2021-07-13  Layout adjustments
	.GridCell {
		.LayoutGrid--rowHeight-fixed &__content {
			min-height: 100%;
		}
		// auf den Director-Pages sollen alle Project-Boxes,
		// auch die deren Video nicht mit der ration von 16:9 vorliegen,
		// auf 16:9 gezwungen werden
		// das gilt allerdings nur für landscape videos
		.DirectorsView .LayoutGrid--rowHeight-fixed &--orientation-landscape &__content {
			width: 100% !important;
		}

		&__nextProjectLink {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			right: 0;
			padding-left: 0.2em;
			padding-top: 0.2em;
		}
	}

	@media @mediaQueries[xs] {}
	@media @mediaQueries[sm] {}
	@media @mediaQueries[md] {}
	@media @mediaQueries[dt] {}
	@media @mediaQueries[lg] {}
	@media @mediaQueries[xl] {}
</style>
