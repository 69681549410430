var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.elmTag,{tag:"div",staticClass:"MediaBox",class:_vm.elmClasses,attrs:{"role":"button","href":_vm.elmHref}},[_c('div',{staticClass:"MediaBox__wrapper"},[(_vm.videoObject)?_c('MhVideo',{ref:"mhVideoRef",staticClass:"MediaBox__clip",class:{
          'MediaBox__clip--isPortrait': _vm.isPortraitImage,
          'MediaBox__clip--isLandscape': _vm.isLandscapeImage
        },attrs:{"videoObject":_vm.videoObject,"autoplay":_vm._autoplayClip,"controls":_vm.showVideoControls,"playClipOnHover":_vm.playClipOnHover,"muted":_vm.isVideoMuted,"allowLoad":_vm.pageIsVisible,"poster":_vm.poster,"preload":_vm.preload}}):_vm._e(),_c('MhImage',{staticClass:"MediaBox__image",class:{
        'MediaBox__image--isPortrait': _vm.isPortraitImage,
        'MediaBox__image--isLandscape': _vm.isLandscapeImage,
        'MediaBox__image--hide': _vm.store.currentlyActiveVideoId === _vm.videoObject.id && !_vm.isProjectOverlay,
        'MediaBox__image--autoHeight': _vm.isProjectOverlay
      },attrs:{"imageObject":_vm.image,"mode":_vm.app.isMqBelowDt ? 'img' : 'cover',"loadingAttr":'eager',"allowLoad":_vm.pageIsVisible}})],1),_c('div',{staticClass:"MediaBox__title",class:{
      'MediaBox__title--show': _vm.store.currentlyActiveVideoId === _vm.videoObject.id
    }},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.title)}})]),_c('div',{staticClass:"MediaBox__inViewportDebug"},[_vm._v(_vm._s(_vm.inViewport))])])}
var staticRenderFns = []

export { render, staticRenderFns }